//common classes
.visually-hidden {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
}


.show {
    display: block !important;
}

.hide {
    display: none !important;
}


.overflow-hidden {
    overflow: hidden;
}


.margin-top-right {
    margin-top: 10px;
    margin-right: 10px;
}

.margin-top-left {
    margin-top: 10px;
    margin-left: 10px;
}

.margin-bottom-right {
    margin-bottom: 10px;
    margin-right: 10px;
}

.margin-bottom-left {
    margin-bottom: 10px;
    margin-left: 10px;
}

.margin-right {
    margin-right: 10px;
}

.margin-left {
    margin-left: 10px;
}

.margin-left-none {
    margin-left: 0 !important;
}

.margin-right-none {
    margin-right: 0 !important;
}

.pb-none {
    padding-bottom: 0;
}

.mb-none {
    margin-bottom: 0 !important;
}

.underline {
    text-decoration: underline;
}

.underline-none {
    text-decoration: none !important;
}

.border-no {
    border: 0 !important;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #e8d9c2;
    z-index: 5;
    cursor: pointer;

    &.active {
        bottom: 0;
    }
}


//close-btn
// .close {
//     position: relative;
//     margin-bottom: 30px;
//     width: 32px;
//     height: 32px;
//     cursor: pointer;
//     right: 15px;
//     top: 15px;

//     &::before,
//     &::after {
//         position: absolute;
//         left: 15px;
//         top: 0;
//         content: ' ';
//         height: 25px;
//         width: 2px;
//         background: $text-color;
//     }

//     &::before {
//         transform: rotate(45deg);
//     }

//     &::after {
//         transform: rotate(-45deg);
//     }

//     &:hover::after,
//     &:focus::after,
//     &:hover::before,
//     &:focus::before {
//         background: $grey;
//     }
// }

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1600px;
    width: 100%;
    &--small{
     max-width: 1170px;
    }
    &--medium{
        max-width: 1035px;
    }
    &--supersmall{
        max-width: 766px;
    }
}


// @media (min-width: 1500px) {
//     .container {
//         max-width: 1600px;
//     }
// }

