.video {
    &__info {
        margin-top: 20px;
    }
    &__img{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        // min-height: 500px;
        overflow: hidden;
        img{
            width: auto;
            max-width: none;
        }
        &:hover,
        &:focus{
            .video__play{
                transform: translate(-50%,-50%) scale(1.1);
            }
        }
    }

    &__play{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%) scale(1);
        width: 92px;
        height: 92px;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 50%;
        transition: all .3s linear;
        svg{
            width: 49px;
            max-height: 100%;
        }
    }
    &__iframe{
        max-width: 100%;
    }

    &__item{
       
        .js-iframe{
        // padding-top: 56.25%;
            position: relative;
            overflow: hidden;
            iframe{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;
            }
        }

        &-inner{
            + .video__item-inner{
                margin-top: 20px;
            }
        }
    }
   
}


