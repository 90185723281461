.footer {
    border-top: 1px solid $secondary;
    padding-top: 40px;
    margin-top: 70px;
    &__col{
        margin-bottom: 25px;
        &:first-child{
            margin-bottom: 35px;
        }
        &:nth-child(2){
            margin-bottom: 30px;
        }
        &:nth-child(2){
            margin-bottom: 20px;
        }
        &:nth-child(2){
            margin-bottom: 40px;
        }
    }

    .logo{
        width: 100%;
        margin: 0 auto;
        display: block;
        max-width: 230px;
        svg{
            width: 100%;
            height: auto;
        }
    }
    &__copyright{
        text-align: center;
        padding: 5px 15px;
        background-color: $secondary;
        font-size: 14px;
    }
    .contacts__link{
        &:hover,
        &:focus{
            color: #CCA975;
        }
    }
    .socials__link{
        &:hover,
        &:focus{
            svg{
                fill: #572B24;
            }
        }
    }
}


@media (min-width: $btg-medium) {
    .footer{
        &__inner{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            margin: 0 -15px !important;
        }
        &__col{
            width: 50%;
            padding: 0 15px;
        }
        .logo{
            width: 238px;
            max-width: 100%;
        }

        .contacts__item,
        .contacts__link:not(.contacts__link--phone){
             font-size: 16px;
        }
    }

}

@media (min-width: $IpadPro) {
    .footer{
        &__inner{
            justify-content: space-between;
            padding-bottom: 58px;
        }
        &__col{
            width: auto;
            &:nth-child(1n){
                margin-bottom: 0;
            }
        }
        .contacts{
            &__item,
            &__link{
                text-align: left;
            }
         }
        .socials{
            &__list{
                margin: 0 -7.5px;
                li{
                    margin: 0 7.5px;
                }
            }
        }

    }
}
@media (min-width: $btg-extraLarge) {
    .footer{
        &__inner{
            padding-top: 45px;
            margin-top:110px;
        }
        .logo{
            width: 320px;
        }

    }
}
