@import "../_variables.scss";


p + .spoiler{
    &:first-of-type{
        margin-top: 35px;
    }
}




.spoiler {
    border-top: 1px solid $brown;
    position: relative;
    // margin-left: -15px;
    // &::before{
    //     content: "";
    //     position: absolute;
    //     height: 1px;
    //     left: 0;
    //     right: 0;
    //     background-color: red;
    // }
    cursor: pointer;
    .show-icon {
        display: none;
    }
    &:last-of-type{
        border-bottom: 1px solid $brown;
        margin-bottom: 30px;
    }
    &.active{
        .spoiler-title{
            color: $info;
            &::before {
                background-image: url(/images/minus-brown.svg);
            }
        }
    }
    &-title {
        font-family: $ff-italic;
        position: relative;
        padding-right: 20px;
        color: $brown2;
        font-size: 16px;
        line-height: 1.5;
        width: 100%;
        text-align: left;
        padding: 13px 15px ;
        padding-right: 50px;
        &::before {
            content: '';
            position: absolute;
            display: block;
            width: 20px;
            height: 20px;
            background-image: url(/images/plus.svg);
            right: 24px;
        }

        @media(min-width: $IpadPro) {
            padding-top: 18px;
            padding-bottom: 18px;
        }

    }
    &-content{
        display: none;
        font-size: 16px;
        padding: 0 15px 15px 15px;
        padding-right: 50px;
        // margin-top: -5px;
        // @media(min-width: $btg-medium) {
        //     padding-left: 25px;
        // }
    }
}


@media (max-width: $btg-small) {
    .container .admin__content .spoiler{
        margin-left: -15px;
        margin-right: -15px;
    }
}
