// npm
// @import "public/fonts/montserrat/stylesheet";
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');

@import "public/fonts/roboto/stylesheet";
@import "public/fonts/merrieweather/stylesheet";

@import "~normalize.css";
@import "~magnific-popup/dist/magnific-popup.css";
@import "~slick-carousel/slick/slick.css";
@import "~malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
// @import "~selectric/src/selectric.scss";

@import "_variables.scss";
@import "_common_classes.scss";
@import "_common.scss";
@import "_default.scss";
@import "modules/video.scss";
@import "modules/gallery.scss";
@import "modules/breadcrumbs.scss";
@import "modules/admin.scss";
@import "modules/spoiler.scss";
@import "modules/sections.scss";
@import "modules/js.scss";

@import "modules/page.scss";
@import "modules/not-found.scss";
@import "modules/forms.scss";
@import "modules/search.scss";

@import "modules/modals.scss";
@import "modules/header.scss";
@import "modules/footer.scss";
@import "modules/nav-menu.scss";