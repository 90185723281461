.search{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    padding: 20px 0;
    &__show-btn{
        padding: 7px;
        margin: 0 16px;
        svg{
            width: 19px;
            height: 19px;
            fill: $text-color;
        }
        &:hover,
        &:focus{
          svg{
            fill: $info;
          }
        }
    }

    &__top{
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .mfp-close {
            position: relative !important;
            top: 0 !important;
            left: 0 !important;
        }
    }

    &__middle{
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        padding: 10px 15px;
    }
    &__bottom{
        flex: 0 0 auto;
        border-color: white;
        z-index: 3;;
    }
    .socials__list{
        justify-content: flex-start;
        svg{
            fill:  #CCA975;
        }
        a:hover,
        a:focus{
            svg{
                fill: #FAEDDC;
            }
        }
    }

    &__form{
        position: relative;
        width: 100%;
        max-width: 562px;
        margin: 0 auto;
        // &::before{
        //     content:  "";
        //     position: absolute;
        //     height: 50%;
        //     width: 1px;
        //     left: 7px;
        //     top: 50%;
        //     transform: translateY(-50%);
        //     background-color: #CCA975;
        // }
    }
    &__input{
        color: #FAEDDC;
        font-size: 16px;
        font-family: $ff-italic;
        line-height: 1;
        border-bottom: 1px solid #FAEDDC;
        width: 100%;
        position: relative;
        padding: 10px 30px 10px 15px;
        &::placeholder {
            color: #FAEDDC;
            font-family: $ff-italic;
            font-size: 16px;
        }
    }

    &__btn{
        width: 20px;
        height: 20px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        margin-bottom: 1px;
        svg{
            max-width: 100%;
            max-height: 100%;
            fill: #CCA975;
        }
    }
}

.mfp-full.mfp-close-btn-in {
    .mfp-close{
        top: 31px;
        right: 25px;
        &::after,
        &::before{
            background: $white;
        }
        &:hover::after,
        &:focus::after,
        &:hover::before,
        &:focus::before {
            background:  $gold !important;
        }
    }

    .mfp-content{
        height: 100%;
    }
}

.mfp-bg.mfp-full{
    opacity: 99%;
}

.mfp-search .nav__wr,
.mfp-nav .search__form{
    display: none;
}

@media (min-width: $IpadPro) {
    .search{
        padding: 50px 0;
        &__middle{
            flex: 1 0 auto;
            display: flex;
            align-items: center;
            padding-bottom: 37px;
            padding-top: 10px;
        }
        &__show-btn{
            margin: 0 25px;
            svg{
                width: 22px;
                height: 22px;
            }
        }


        .logo{
            width: 214px;
        }
        // &__form{
        //     &::before{
        //         left: 10px;
        //     }
        // }

        &__input{
            font-size: 20px;
             padding: 12px 30px 12px 21px;
            &::placeholder {
                font-size: 20px;
            }
        }

        &__btn{
            width: 23px;
            height: 23px;
            margin-bottom: 1px;
        }
    }
}
