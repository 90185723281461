// float img 
.margin-left-none{
  margin-left: 0 !important;
}
.margin-right-none{
  margin-right: 0 !important;
}

.mb-none{
    margin-bottom: 0 !important;
}



// table and video
.admin{
    .admin__table-wr{
		max-width: 100%;
		overflow-x: auto;
	}
	table, th, td {
		// border: 1px solid #394a94;
    	border: 1px solid  #ececec;
	   border-collapse: collapse;
	}
	table{
		width: 100%;
		font-size: 16px;
		tbody tr:nth-child(even) {
		    background: #f5f5f5;
		}
		th,
		td{
    	word-break: initial;
	    // flex: 1 0 auto;
	    padding: 7.5px;
	    vertical-align: middle;
	    // width: auto;
		}
	}
	thead{
		tr{
			background: #ffa401;
		}
		th{
			padding: 15px 10px;
			color: #fff;
		}
    }
    &__video{
        position: relative;
        padding-top: 56.25%;
        overflow: hidden;
        iframe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }
}

.admin {
    &__text-center {
        text-align: center;
    }

    &__content {
        font-size: 16px;
        line-height: 1.5;
        max-width: 100%;
        &--mt{
            margin-top: 5px;
        }
        h1,
        h2,
        h3,
        h4 {
            line-height: 1.4;
            color: $brown;
            font-family: $ff-italic;
            margin-bottom:12px;
        }
        p+ h2{
            // margin-top: 23px;
            margin-top: 28px;
        }
        h1{
            font-size: 44px;
        }

        h2 {
            font-size: 29px;
            // margin-bottom:17px;
        }


        p+ h2{
            // margin-top: 25px;
            margin-top: 30px;
        }

        h3 {
            font-size: 23px;
            // margin-bottom: 15px;
        }

        p+ h3{
            // margin-top: 20px;
            margin-top: 25px;
        }


        h4 {
            font-size: 16px;
            // margin-bottom: 12px;
        }

        p+ h4{
            // margin-top: 18px;
            margin-top: 23px;
        }

        img {
            display: inline-block;
            margin: 0 auto;
            max-width: 100%;
            height: auto !important;
        }

        p:not(:last-child) {
            margin-bottom: 12px;
        }

        ol,
        ul {
            + * {
                margin-top: 25px;
            }
            list-style-type: none;
            text-align: left;
            li {
                position: relative;
                margin-top: 12px;
                line-height: 1.3;
                &:first-letter {
                    text-transform: uppercase;
                }

                &::before {
                    content: "";
                    position: absolute;
                }
            }
        }

        ul {
            li {
                // padding-left: 16px;
                &::before {
                    content: "";
                    position: relative;
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    background-color: $brown;
                    vertical-align: middle;
                    margin: 0 7px 0 5px;
                }
            }
        }

        ol {
            li {
                counter-increment: my-awesome-counter;
                &::before {
                    content: counter(my-awesome-counter) '.';
                    position: static;
                    left: 0;
                    top: 0px;
                    color: $brown;
                    font-size: 16px;
                    font-family: $ff-second;
                    margin-right: 3px;
                    margin-left: 5px;
                }
            }
        }
    }

    &__video {
        position: relative;
        padding-top: 56.25%;
        overflow: hidden;
        width: 100%;
        // max-width: 922px;
        max-width: 100%;
        margin: 0 auto 30px;
        iframe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }
}



@media (max-width: $btg-medium) {

    .admin {
        &__content {
            ol,
            ul {
                + * {
                    margin-top: 20px;
                }
            }

            h1{
                font-size: 29px;
            }
            h2 {
                font-size: 22px;
            }
    
    
            p+ h2{
                margin-top: 25px;
            }
    
            h3 {
                font-size: 20px;
            }
        }
    }
}
