input:-internal-autofill-selected {
    background-color: transparent !important;
}

// disabled arrow on input type -number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

label {
    cursor: pointer;
}

.form {
    &__info-top {
        font-size: 18px;
        font-family: $ff-italic;
        margin-top: 20px;
        margin-bottom: 20px;
        color: $brown2;
    }

    &__item {
        width: 100%;
        position: relative;
        margin-bottom: 25px;
        &--icon {
            .form__label-top {
                left: 42px;
            }
            .form__input {
                padding-left: 42px;
            }
        }

        //   &--checkbox{
        //       label{
        //           line-height: 20px;
        //           cursor: pointer;
        //           position: relative;
        //           padding-left: 22px;
        //           display: block;
        //           &::before {
        //               content: '';
        //               display: block;
        //               width: 15px;
        //               height: 15px;
        //               background:#f6f6f6;
        //               position: absolute;
        //               left: 0;
        //               top: 2px;
        //               box-sizing: border-box;
        //           }
        //       }
        //       input[type="radio"]:checked + label:after, input[type="checkbox"]:checked + label:after {
        //           content: '';
        //           display: block;
        //           width: 15px;
        //           height: 15px;
        //           background: $brown2;
        //           position: absolute;
        //           left: 0;
        //           top: 2px;
        //       }
        // }
        &-icon {
            position: absolute;
            top: 50%;
            left: 18px;
            transform: translateY(-50%);
            width: 15px;
            height: 15px;
            margin-top: 1px;
            svg {
                max-width: 100%;
                max-height: 100%;
                fill: rgb(204, 169, 117);
            }
        }
    }

    &__input {
        outline: none;
        background: none;
        background-color: transparent;
        width: 100%;
        padding: 8px 15px;
        font-size: 15px;
        height: 50px;
        color: $black;
        background: #f6f6f6;
        border: 1px solid #f6f6f6;
        &:focus {
            border-color: #cbcbcb;
            background: #fff;
        }

        &.error {
            border-color: $secondary;
        }
    }

    // &__radio-wr {
    //     &:not(:last-child) {
    //         margin-bottom: 12px;
    //     }
    // }

    // &__radio-label {
    //     padding-left: 30px;
    //     position: relative;
    //     cursor: pointer;

    //     &:before {
    //         content: "";
    //         position: absolute;
    //         left: 0;
    //         height: 20px;
    //         width: 20px;
    //         border: 2px solid $gold;
    //         border-radius: 50%;
    //         transform: translateY(-50%);
    //         top: 50%;
    //     }

    // }

    // &__radio:checked {
    //     &+.form__radio-label {
    //         &:after {
    //             content: '';
    //             position: absolute;
    //             border-radius: 50%;
    //             width: 10px;
    //             height: 10px;
    //             background: $gold;
    //             // top: 5px;
    //             left: 5px;
    //             transform: translateY(-50%);
    //             top: 50%;
    //         }
    //     }
    // }

    // &__radio:disabled + .form__radio-label {
    //     opacity: 0.4;
    //     cursor: not-allowed;
    // }

    // &__argeement {
    //     margin-bottom: 25px;
    // }

    // &__argeement-link {
    //     color: $gold;

    //     &:hover,
    //     &:focus {
    //         color: $gold;
    //     }
    // }

    &__argeement-checkbox {
        &:checked + .form__argeement-label {
            &::before {
                content: "\2713";
                border-color: transparent;
                // background-image: url(../images/icons/ckeckbox.png);
                background-position: center;
                background: $gold;
            }
        }
    }

    .form__argeement {
        & a {
            text-decoration: underline;
        }
    }

    &__argeement-label {
        display: block;
        position: relative;
        padding-left: 30px;
        color: #3b3b3b;
        cursor: pointer;
        font-size: 16px;
        margin-bottom: 20px;
        &::before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            text-align: center;
            color: $white;
            border: 1px solid #cca975;
            border-radius: 6px;
            background: #fff;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    &__bottom-wr {
        min-height: 45px;
    }
    &__bottom-info {
        margin-bottom: 25px;
        font-size: 16px;
    }
    &__info-descr {
        margin-bottom: 30px;
    }

    &__label-top {
        position: absolute;
        top: 18px;
        left: 15px;
        pointer-events: none;
        color: $black;
        font-size: 15px;
        transition: 0.3s;
        line-height: 1.1;
        opacity: 0.6;
        &.active {
            color: $gold;
            top: -8px;
            background: $white;
            opacity: 1;
        }
    }

    &__input--textarea {
        height: 100px;
        resize: none;
        padding: 15px;
    }

    ///// change to span in validatng

    label.error {
        width: 100%;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        color: $error;
        margin-top: 10px;
        font-size: 16px;
        color: $gold;
    }

    &__error {
        input,
        textarea {
            border-color: $gold;
        }
    }

    &__error,
    &__size-error,
    &__success {
        width: 100%;
        display: none;
        margin: 10px 0;
        text-align: center;
        font-size: 16px;
        color: $gold;
    }

    &__error,
    &__size-error {
        color: $gold;
    }

    &__title {
        font-family: $ff-second;
        font-size: 26px;
        padding-bottom: 10px;
    }

    &__item-title {
        color: #282828;
        margin-bottom: 10px;
    }

    &__file-lt {
        display: none;
    }
    &__file-label {
        display: inline-flex;
        font-size: 15px;
        padding-left: 30px;
        position: relative;
        text-decoration: underline;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background-image: url(/images/file.png);
            background-size: contain;
        }
        + .form__file-wr {
            margin-top: 7px;
        }
    }

    &__loader {
        margin: 0;
        overflow: hidden;
        display: none;
        svg {
            animation: spin 4s linear infinite;
            width: 40px;
            height: 40px;
            fill: $gold;
        }
        &.show {
            display: block;
        }
    }

    &__file-remove {
        position: relative;
        width: 15px;
        height: 15px;
        margin-left: 10px;
        min-width: 15px;
        cursor: pointer;
        &::before,
        &::after {
            position: absolute;
            left: 7px;
            top: 1px;
            content: " ";
            height: 15px;
            width: 1px;
            background: #cca975;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }

        &:hover::after,
        &:focus::after,
        &:hover::before,
        &:focus::before {
            //   background: #CCA975;
        }
    }
}

// styles for file
.form__file-wr.active {
    .form__file-noactive {
        display: none;
    }
    .form__file-lt {
        display: block;
    }
}

.form__file-wr:nth-child(2) {
    display: none;
}

.form__file-wr:last-child {
    display: none;
}
.form__item-group {
    margin-top: 15px;
}

.form__file-wr {
    margin: 4px 0;
}

.form__file-wr input {
    display: none;
}

.form__item-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    .form__file-wr.active {
        order: 2;
    }
}
// styles for file

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media (max-width: $IpadPro) {
}