// header sticky


.main-wrap{
    padding-top: 59px;
}
.header{
    z-index: 3;
    position: relative;
    background-color: $white;
    position: fixed;
    top: 0;
    z-index: 5;
    width: 100%;
    &__top{
        padding: 8px 0;
        border-bottom: 1px solid #F6F6F6;
        display: none;
        &-wr{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0 -10px;
            .header__info_cover {
                padding: 0 10px;
            }
        }
    }
    // &--sticky {
    //     padding-bottom: 45px;

    //     .header__bottom {
    //         position: fixed;
    //         top: 0;
    //         z-index: 100;
    //         width: 100%;
    //         background-color: $white;
    //     }
    // }


    &__bottom{
        // &--sticky{
        //     background-color: white;
        //     position: fixed;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        // }
        position: sticky;
        top: 0;
        z-index: 5;
        &-wr{
            padding: 9px 0;
            display: flex;
            align-items: center;

        }
        border-bottom: 1px solid #CCA975;
    }
    &__callback-desk{
        display: none;
    }
    &__info{
        position: relative;
        padding-left: 21px;
        font-size: 15px;
        line-height: 1.4;
        &::before{
            content: "";
            position: absolute;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            left: 2px;
            transform: translateY(-50%);
            top: 50%;
        }
        &--time{
            &::before{
                width: 15px;
                height: 15px;
                background-image: url(/images/time.svg);
                margin-top: 1px;
            }
        }
        &--address{
            &::before{
                width: 12px;
                height: 16px;
                background-image: url(/images/address.svg);
            }
        }
    }
    .logo{
        width: 158px;
        height: 40px;
        svg{
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }

    }
    &__phone{
        display: none;
        margin-left: auto;
        margin-right: 20px;
        &-link{
            font-size: 21px;
            line-height: 31px;
            transition: color 0.3s ease-in;
            &:hover,
            &:focus{
                color: $info;
            }
        }
    }
}

.logo{
    width: 158px;
    svg{
        max-width: 100%;
        height: auto;
        max-height: 100%;
    }

}

.call{
    padding: 7px;
    margin-left: auto;
    svg{
        width: 22px;
        height: 22px;
    }
    &__btm-wr{
        display: flex;
        align-items: center;
        justify-content: center;
        .btn + .btn{
            margin-left: 15px;
        }
    }
}


@media (min-width: $btg-medium) {

}

@media (min-width: $IpadPro) {
    .main-wrap{
        padding-top: 0;
    }

    .header{
        z-index: 3;
        position: relative;
        background-color: $white;
        &--sticky{
            padding-bottom: 104px;
            .header__bottom {
                position: fixed;
                top: 0;
                z-index: 100;
                width: 100%;
                background-color: $white;
            }
        }

        &__top{
            display: block;
        }
        &__callback-desk{
            display: block;
        }
        .logo{
            width: 238px;
            height: auto;
        }
        &__callback-mb{
            display: none;
        }
        &__phone{
            display: block;
        }
        &__bottom{
            &-wr{
                padding: 22px 0;
            }
        }
    }
}
