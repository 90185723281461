.gallery {
    &__slider-wr {
        position: relative;
        // overflow: hidden;
        .gallery__controll {
            z-index: 1;
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &__photo_name {
        text-align: center;
        font-size: 15px;
        margin-top: 10px;
        font-family: $ff-italic;
    }
    &__link {
        display: block;
        position: relative;
        &::before{
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: rgba(107, 67, 67,0.45);
            left: 0;
            top: 0;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.3s ease-in;
        }
        &::after{
            content: "";
            position: absolute;
            height: 48px;
            width: 48px;
            background-color: rgba(107, 67, 67,0.95);
            left: 50%;
            top: 50%;
            z-index: 2;
            transform: translate(-50%,-50%);
            background: url(/images/loop.png) no-repeat center ;
            transition: opacity 0.3s ease-in;
            opacity: 0;
        }
        &:hover{
            &::before,
            &::after{
                opacity: 1;
            }
        }
        &-text{
            font-size: 16px;
            color: #000;
            margin-top: 8px;
            text-align: center;
        }
    }

    &__controll {
        // display: none !important;
        display: none;
        align-items: center;
        justify-content: center;
        z-index: 1;
        cursor: pointer;
        background-color: rgba(250, 237, 220, 0.55);
        padding: 5px;
        width: 50px;
        height: 50px;
        transition:  background-color 0.5s ease;
        &:hover,
        &:focus {
            background-color: #FAEDDC;
            // .sprite-icon {
            //     fill: #828282;
            // }
        }

        &.slick__controll.slick-hidden {
            display: none;
        }

        &.slick-disabled {
            cursor: not-allowed;
            display: none !important;
        }

        .sprite-icon {
            fill: $brown;
            width: 15px;
            height: 30px;
        }
    }
    &__controlls-wr{
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 2;
        display: flex;
        width: 211px;
        .gallery__controll{
            flex-grow: 1;
            height: 70px;
        }
        &::after{
            content: "";
            position: absolute;
            height: 80%;
            width: 1px;
            background-color: $brown;
            left: 50%;
            top: 50%;
            z-index: 2;
            transform: translate(-50%,-50%);
        }
        .slick-disabled{
            display:  flex !important;
            cursor: not-allowed;
            background-color: rgba(250, 237, 220, 0.55) !important;

            &:hover,
            &:focus{
                background-color: rgba(250, 237, 220, 0.55) !important;
            }
        }
        @media (max-width: $IpadPro) {
            display: none;
        }
    }

    &__next {
        right: 0;
    }

    &__prev {
        left: 0;
    }

    &__item-wr {
        padding: 0 7.5px;
        // img{
        //     width: auto;
        // }
    }

    &__slider{
        margin: 0 -7.5px;
    }
    &__list{

    }
}

@media (min-width: $btg-medium) {
    .gallery {
        &__controll{
            // display: flex !important;
            display: flex;
        }
    }
}

@media (min-width: $btg-extraLarge) {
    .gallery {
        &__item-wr {
            padding: 0 12.5px;
        }

        &__slider{
            margin: 0 -12.5px;
        }
        &__controll {
            width: 70px;
            height: 70px;
        }
    }
}

@media (max-width: $btg-small) {
    .gallery--no-mobile-cont{
        .gallery__controll {
            display: none !important;
        }
    }

    .section--gallery .gallery {
        margin-left: -15px;
        margin-right: -15px;
        overflow: hidden;
    }
}
