
.section{
    &--mt{
      margin-top: 55px;
    }
    &--mts{
      margin-top: 45px;
    }
    &--overflow{
      overflow: hidden;
    }
    &__title{
      font-size: 27px;
      font-family: $ff-italic;
      color: $brown2;
      letter-spacing: 0.02em;
      padding-left: 40px;
      margin-bottom: 20px;
      position: relative;
      &::before{
          content: "";
          position: absolute;
          width: 31px;
          height: 2px;
          left: 0;
          // top: 50%;
          // transform: translateY(-50%);
          top: 14px;
          background-color: $brown2;
      }
      &-dt {
        display: none;
      }
      &--center{
            padding-left: 0 !important;
            &::before,
            &::after{
                display: none;
            }
            span {
                position: relative;
                padding-left: 27px;
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    // top: 50%;
                    // transform: translateY(-50%);
                    top: 14px;
                    top: 12px;
                    width: 20px;
                    height: 2px;
                    background-color: $brown2;
                }
            }
        }
    }
}

@media (min-width: $btg-medium) {
    
}

@media (min-width: $IpadPro) {
    .section{
        &__title{
            font-size: 35px;
            &::before{
                top: 17px;
            }
            &--center{
                text-align: center;
                span {
                    padding: 0 50px;

                    &::before {
                        width: 35px;
                        top: 16px;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        right: 0;
                        // top: 50%;
                        // transform: translateY(-50%);
                        top: 16px;
                        width: 35px;
                        background-color: $brown2;
                        height: 2px;
                    }
                }
            }
        }
    }
}


@media (min-width: $btg-extraLarge) {
    .section{
      &--mt{
        margin-top: 100px;
      }
      &--mts{
        margin-top: 75px;
      }
      .removeeee__title{
        font-size: 44px;
        margin-bottom: 30px;
        padding-left: 60px;
        &::before{
          width: 50px;
          top: 22px;
        }
        &--center{
          span {
              &::after ,
              &::before {
                top: 20px;
              }
            }
          }
      }
    }
}