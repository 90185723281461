.nav{
  &__btn{
      width: 38px;
      height: 38px;
      z-index: 5;
      outline: none;
      cursor: pointer;
      svg{
        width: 100%;
        fill: $text-color;
      }
      &:hover,
      &:focus{
        svg{
          fill: $info;
        }
    }
  }
}

@media (min-width: $IpadPro) {
  .nav{
      &__btn{
          width: 45px;
          height: 45px;
      }
  }
}

  .burger-menu {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      width: 100%;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      // justify-content: center;
      align-items: center;
      // background-color: rgba(0, 0, 0, 0.85);
      overflow: hidden auto;
      display: none;
      z-index: 1;
      &.active {
        display: flex;
        opacity: 1;
      }
      &__nav {
        width: 33%;
        //  max-width: 633px;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all 1s ease-in-out;
        opacity: 1;
        &.main-nav {
          transform: translateX(100%);
          &.js_first {
            transform: translateX(25%);
          }
          &.js_second {
            transform: translateX(15%);
          }
        }
        &.first {
          transform: translate(50%);
          &.js_second {
            transform: translateX(25%);
          }
        }
        &.first, &.second {
          display: none;
        }
        &.second {
          width: 26%;
          max-width: 512px;
          transform: translate(30%);
        }
      }
      &__footer {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        padding: 0 70px 45px 60px;
      }
      .menu-wrapper{
        padding: 20px;
      }
  }
  

  .nav-menu-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    &__item {
      // max-width: 300px;
      max-width: 400px;
      transform: translateX(50px);
      opacity: 0;
      transition: all .5s ease-in-out;
      cursor: pointer;
      &.show {
        opacity: 1;
        transform: translateX(0);
      }
      & + .nav-menu-list__item {
        margin-top: 20px;
      }
    }
    &__link {
      color: #FAEDDC;
      font-size: 16px;
      line-height: 1;
      letter-spacing: 0.1em;
      &:active,
      &:focus,
      &:visited {
        color: #FAEDDC;
      }
      &:hover,
      &.active {
        color: #CCA975;
      }
      &.active-link {
        color: #CCA975;
      }
    }
    &--main {
      margin-bottom: 45px;
      .nav-menu-list {
        &__item {
          & + .nav-menu-list__item {
            margin-top: 25px;
          }
        }
        &__link {
          font-size: 29px;
          line-height: 1;
          letter-spacing: 0.02em;
          font-family: $ff-italic;
        }
      }
    }
    &--flex{
      column-count: 2;
      column-gap: 120px;
    }
  }
  
  .submenu {
    position: relative;
    padding: 64px 0;
    &__inner {
      display: none;
    }
    .nav-menu-list {
      padding: 0 0 0 10px;
      &__item {
        margin: 0;
        & + .nav-menu-list__item {
          margin-top: 20px;
        }
      }
    }
    .nav-menu-list__link{
      font-size: 20px;
      font-family: $ff-italic;
    }
    &__line {
      position: absolute;
      top: 0;
      left: -55px;
      width: 1px;
      height: 0;
      background-color: #CCA975;
    }
    &__back {
      display: none;
      padding-left: 18px;
      margin: 0 0 20px 15px;
      position: relative;
      color: #FAEDDC;
      transform: translateX(50px);
      opacity: 0;
      transition: all .5s ease-in-out;
      font-size: 16px;
      &.nav-menu-list__item {
        margin: 0 0 20px 15px;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        border-bottom: 2px solid #CCA975;
        border-left: 2px solid #CCA975;
        width: 9px;
        height: 9px;
      }
    }
  }
  

  @keyframes scaleImg {
    100% {
      transform: scale3d(1.2,1.2,1);
    }
  }
  
  @media screen and (max-width:1366px) {
    .touchevents {
      .burger-contacts {
        margin-right: 70px;
      }
    }
  }
  
  @media screen and (max-width:1024px) {
    .submenu {
      &__line {
        left: -35px;
      }
    }
    .burger-menu {
      &__footer {
        padding: 0 30px 20px;
      }
    }
  }
  @media screen and (max-width:992px) {
    .submenu {
      &__back {
        display: block;
        opacity: 0;
      }
    }
    .burger-menu {
      transform: translateX(-33%);
      width: 300vw;
      &__nav {
        display: table-cell;
        vertical-align: middle;
        align-items: center;
        width: 100vw;
        max-width: none;
        transform: translate(100%, 0%) matrix(1, 0, 0, 1, 0, 0);
        &.main-nav {
          &.js_first {
            transform: translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0);
          }
          &.js_second {
            transform: translate(-100%, 0%) matrix(1, 0, 0, 1, 0, 0);
          }
        }
        &.first {
          &.active {
            transform: translate(25%, 0%) matrix(1, 0, 0, 1, 0, 0);
          }
          &.js_second {
            transform: translate(-100%, 0%) matrix(1, 0, 0, 1, 0, 0);
          }
        }
        &.second {
          opacity: 0;
          &.active {
            transform: translate(-100%, 0%) matrix(1, 0, 0, 1, 0, 0);
            opacity: 1;
          }
        }
      }
    }
  }

  @media screen and (max-width:568px) {
    .header-new {
      &__link {
        display: none;
 
      }
    }
    .nav-menu-list {
      max-height: 50vh;
      overflow: hidden auto;
      &__item {
        & + .nav-menu-list__item {
            margin-top: 11px;
          }
        }
      &__link {
        font-size: 16px;
        line-height: 1.2;
      }
      &--main {
        margin-bottom: 35px;
        .nav-menu-list {
          &__item {
            & + .nav-menu-list__item {
              margin-top: 15px;
            }
          }
          &__link {
            font-size: 22px;
          }
        }
      }
      &--flex{
        column-gap: 10px;
        .nav-menu-list__link{
          letter-spacing: initial;
        }
        .nav-menu-list__item + .nav-menu-list__item {
          margin-top: 14px;
         }
      }
    }
    .burger-menu{
      .submenu {
        position: relative;
        padding: 30px 0;
        .nav-menu-list {
          padding: 0 0 0 10px;
          &__item {
            & + .nav-menu-list__item {
              margin-top: 15px;
            }
          }
        }
        .nav-menu-list__link{
          font-size: 18px;
        }
        &__line {
          left: -15px;
        }
      
      }
      
    }
  }

  @media (min-width:1200px) {
    .burger-menu .menu-wrapper{
      padding-right: 0;
    }
    .burger-menu {

      &__nav {
        width: 33%;
        &.main-nav {
          &.js_first {
            transform: translateX(72%);
          }
          &.js_second {
            transform: translateX(15%);
          }
        }
        &.first {
          transform: translate(80%);
        }
      }
    }
  }

  @media (min-width: 1200px) and (max-width: 1400px) {
    .burger-menu {

      &__nav {
        &.main-nav {
          &.js_first {
          }
          &.js_second {
            // transform: translateX(15%);
          }
        }
        &.first {
          // transform: translate(80%);
        }
      }
    }
    .submenu__line{
      left: -18px;
    }
    .burger-menu__nav.first{
      padding-left: 1vw;
    }
  }

  @media (min-width: 1500px)  {
    .menu-wrapper {
      padding-right: 0;
      width: 100%;
      max-width: 515px;
      margin-left: auto;
  }
}