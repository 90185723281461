.not-found{
    text-align: center;
    padding: 30px 0;
    padding-top: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.not-found__title{
    font-size: 100px;
    line-height: 1;
    font-weight: normal;
    margin-top: 0;
}

.not-found__wrapper-info{
    margin-bottom: 20px;
}

.not-found-page .main-wrap{
     flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.not-found-page .content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

// fix and remove in wrapper
.main-flex{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 15px !important;
}

@media (min-width: 768px){
.main-flex{
         margin-top: 15px;
    }

}