@font-face {
    font-family: 'robotomedium';
    src: url('/fonts/roboto/roboto-medium-webfont.eot');
    src: url('/fonts/roboto/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/roboto/roboto-medium-webfont.woff2') format('woff2'),
         url('/fonts/roboto/roboto-medium-webfont.woff') format('woff'),
         url('/fonts/roboto/roboto-medium-webfont.svg#robotomedium') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'robotoregular';
    src: url('/fonts/roboto/roboto-regular-webfont.eot');
    src: url('/fonts/roboto/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/roboto/roboto-regular-webfont.woff2') format('woff2'),
         url('/fonts/roboto/roboto-regular-webfont.woff') format('woff'),
         url('/fonts/roboto/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}