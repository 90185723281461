.modals {
    height: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: $white;
    &__top {
        font-family: $ff-italic;
        border-bottom: 1px solid  $info;
        font-size: 21px;
        color: $brown;
        padding: 17px 60px 17px 15px;
    }

    &__content-wr {
        padding: 10px 25px 15px 15px;
        background: #fff;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
        background: #fff;
        flex-grow: 1;
        &--right {
            padding-right: 30px;
        }
    }

    .form__bnt-wr{
        // hide btns effect to prevent scroll
        overflow: hidden;
    }

    &__content{
         overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        .form__wr{
          padding-top: 5px;
        }
    }

    &__descr {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 1.4;
    }
    .btn{
      padding: 17px 5px;
    }
}

.mfp-bg {
  background: #572B24;
  opacity: 92%;
}

.mfp-container{
  padding: 0;
}
.mfp-iframe-holder .mfp-content{
  max-width: 1450px;
}

.modal-dec .mfp-top{
    .mfp-container{
      height: auto;
      justify-content: center;
    }
    .mfp-content{
      height: auto;
      width: 100%;
      color: $text-color;
      max-width: 100%;
      padding: 90px 15px  30px 15px;
      background: $white;
      border-top: 1px solid #FAEDDC;
    }
    .contacts__item a{
      color: $text-color;
      font-weight: 500;
    }
    .modals__callback{
      max-width: 227px;
      display: block;
      margin: 0 auto;
    }

    .contacts__list-wr{
      padding-left: 0;
      margin-bottom: 20px;
      text-align: center;
    }
    
    button.mfp-close, button.mfp-arrow{
      left: 0;
      right: 0;
      top: 30px;
      margin: auto;
      border: 1px solid #DADDE0;
      width: 30px;
      height: 30px;
      line-height: 30px;
      position: absolute;
      color: #000;
    }
  
    &.mfp-wrap{
      top: 55px;
    }
  
    &.mfp-bg{
      background: none;
    }
    &.mfp-wrap{
        background: #572B24;
        opacity: 92%;
    }
    .form__wr{
      max-width: 600px;
      margin: 0 auto;
    }
  }

.mfp-close-btn-in .mfp-close {
  &::before,
  &::after {
      background: $text-color;
  }
}

.mfp-nav,
.mfp-search{
  .mfp-close{
    top: 31px;
    right: 25px;
    &::after,
    &::before{
        background: $white !important;
    }
    &:hover::after,
    &:focus::after,
    &:hover::before,
    &:focus::before {
        background:  $gold !important;
    }
  }
}


body:not(.modal-dec){
  .mfp-close-btn-in .mfp-close {
    line-height: 1;
    font-size: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    top: 20px;
    right: 20px;
    opacity: 1;
    &::before,
    &::after {
        position: absolute;
        left: 7px;
        top: 0;
        content: ' ';
        height: 20px;
        width: 2px;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }

    &:hover::after,
    &:focus::after,
    &:hover::before,
    &:focus::before {
        background:  $gold;
    }
  }
}


.mfp-right {
    .mfp-content {
        width: 100%;
        max-width: 580px;
        height: 100%;
        top: 0;
        right: 0;
        background: $white;
        position: fixed;
        z-index: 20;
        overflow-x: visible;
    }

    &.mfp-wrap .mfp-content {
        right: -1500px;
        transition: all 0.5s ease-out;
    }

    &.mfp-wrap.mfp-ready .mfp-content {
        right: 0;
        transition: all 0.5s ease-out;
    }

    &.mfp-wrap.mfp-removing .mfp-content {
        right: -1500px;
        transition: all 0.5s ease-out;

    }
}

.modal-dec .mfp-top{
  .mfp-content{
    vertical-align: top;
  }
  &.mfp-wrap{
    opacity: 1;
    background-color: transparent;
  }
  .mfp-container{
      height: 100%;
      &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        background: #572B24;
        width: 100%;
        z-index: -1;
        position: absolute;
        opacity: 92%;
        height: 100%;
    }
  }
}

.mfp-nav{
  .mfp-close{
    &:hover::after,
    &:focus::after,
    &:hover::before,
    &:focus::before {
        background:  $gold !important;
    }
  }
}

@media (min-width: $btg-extraLarge) {

  .modals {
    &__top {
        font-size: 25px;
        padding: 20px 60px 25px 30px;
    }

    &__content-wr {
      padding: 30px 60px 30px 30px;

    }
  }
  body:not(.modal-dec){
    .mfp-close-btn-in .mfp-close {
      width: 25px;
      height: 25px;
      top: 26px;
      right: 20px;
      &::before,
      &::after {
          height: 25px;
      }
    }
  }
  
}

@media (max-width: $btg-small) {
 
}


