
.btn {
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    line-height: 1;
    border: none;
    color: $white;
    max-width: 100%;
    width: 240px;
    padding: 15px 5px;
    text-align: center;
    font-family: $ff-italic;
    letter-spacing: 0.02em;
    &:hover,
    &:focus {
      color: $white;
      transition: 0.3s;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
}

.btn--filled{
  background: transparent;
  color: $white;
  width: 200px;
  position: relative;
  span{
    position: relative;
    z-index: 2;
  }

  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: $brown2;
    transition: all 0.3s;
  }
  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    border: 1px solid $brown2;
    transform: scale(1.2,1.2);
  }
  &:hover,
  &:focus {
    color: $brown2;
    &::before{
      opacity: 0;
      transform: scale(0.5,0.5);
    }
    &::after{
      opacity: 1;
      transform: scale(1,1);
    }
  }
  &:disabled{
    &:hover,
    &:focus {
      color: $white;
      &::before{
        opacity: 1;
        transform: none;
      }
      &::after{
        opacity: 0;
        transform: none;
      }
    }
  }
}

.btn--filled-peach{
  color: $text-color;
  background: transparent;
  color: $text-color;
  width: 220px;

  &::before{
    content: '';
    background-color: #FAEDDC;
  }
  &::after{
    border: 2px solid #FAEDDC;
  }
  &:hover,
  &:focus {
    color: $text-color;
  }
}

.btn--filled-gold{
  background: transparent;
  color: $white;
  width: 240px;
  &::before{
    content: '';
    background-color: #CCA975;
  }
  &::after{
    border: 2px solid #CCA975;
  }
  &:hover,
  &:focus {
    color: #CCA975;
  }
}


.btn--border{
  // border: 1px solid $brown2;
  background: rgba(255, 255, 255, 0.75);
  background-color: transparent;
  color: $brown2;
  width: 200px;
  position: relative;
  span{
    position: relative;
    z-index: 2;
  }
  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border: 1px solid $brown2;
  }
  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    background-color: $brown2;
    transition: all 0.3s;
    transform: scale(0.5,0.5);
  }
  &:hover,
  &:focus {
    color: $white;
    &::before{
      opacity: 0;
      transform: scale(1.2,1.2);
    }
    &::after{
      opacity: 1;
      transform: scale(1,1);
    }
  }
}


.contacts{
  &__item{
   font-size: 15px;
   line-height: 1.2;
   text-align: center;
   + .contacts__item{
       margin-top: 14px;
   }
  }
  &__link{
   font-size: 15px;
   line-height: 1.2;
   &--phone{
       font-size: 18px;
       font-family: $ff-second;
   }
  }
}

.socials{
  &__list{
      display: flex;
      justify-content: center;
      margin: 0 -7.5px;
      li{
          margin: 0 7.5px;
      }
      a{
          display: block;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          svg{
              max-width: 100%;
              max-height: 100%;
          }
          &:hover,
          &:focus{
              svg{
                  fill: #572B24;
              }
          }
          // &.socials__link--fbf{
          //   svg{
          //     fill: #405A9A;
          //   }
          // }
          // &.socials__link--inf{
          //   svg{
          //     fill: #C63C7A;
          //
          //   }
          // }
          // &.socials__link--vkf{
          //   svg{
          //     fill: #597DA3;
          //
          //   }
          // }
          // &.socials__link--ytf{
          //   svg{
          //     fill: #C4302B;
          //   }
          // }

      }
      svg{
        fill: #CCA975;
      }

  }
  &__icon{
      width: 16px;
      height: 17px;
      display: block;
      svg{
          display: inline-block;
          width: 16px;
          height: 17px;
      }
  }
  &__link{
      font-size: 15px;
      display: flex !important;
      align-self: center;
      color: $white;
      &-text{
          margin-left: 10px;
      }
      &:hover,
      &:focus{
          color: $white;
      }
  }
}
.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

@media (min-width: $btg-medium) {
  .light {
      &__title {
          &-dt {
              display: inline-block;
          }
          &-mb {
              display: none;
          }
      }
    }
}



@media (min-width: $IpadPro) {
  .btn_desc{
    min-width: 220px;
    padding: 17px 5px;
  }
}


@media (min-width: $btg-extraLarge) {

}
