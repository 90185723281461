*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
   line-height: 1.2;
   font-size: $font-size-base;
   color: $text-color;
   font-family: $ff-main;
   background-color: $white;
}
a {
    cursor: pointer;
    text-decoration: none;
    outline: none;
    font-family: $ff-main;
    font-weight: normal;
    color: $text-color;
    &:hover,
    &:focus,
    &:active,
    &:disabled {
        text-decoration: none;
        color: inherit;
    }
}


ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

address{
    font-style: normal;
}

img {
    width: 100%;
    height: auto;
    display: block;
    max-width: 100%;
    margin: 0 auto;
}

input,
button {
    box-shadow: none;
    border: none;
    outline: none;
    font-family: inherit;
    background-color: transparent;
    font-family: $ff-main;
    &:active,
    &:hover,
    &:focus {
        outline: 0 !important;
        outline-offset: 0;
    }
}

button {
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: normal;
}


// footer to the bottom of the page
* {
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
}

.site {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.main-wrap {
    flex: 1 0 auto;
    // display: flex;
    // flex-direction: column;
}

header,
footer {
    flex: 0 0 auto;
}

// footer to the bottom of the page


.slick-slide {
    outline: none !important;
}