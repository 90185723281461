.page{
    &__title{
        text-align: center;
        font-family: $ff-italic;
        color: $brown2;
        font-size: 29px;
        letter-spacing: 0.02em;
        margin-bottom: 12px;
        margin-top: 40px;
    }
    &__top{
        padding-top: 15px;
        &--mb{
           margin-bottom: 25px;  
        }
        &-inner{
            max-width: 835px;
            margin-left: auto;
            margin-right: auto;
        }
        &--bg{
            padding-bottom: 50px;
        }
        &-banner{
            margin-bottom: 20px;
            overflow-x: hidden;
            .page__title{
                margin-bottom: 0;
            }
        }
    }
    &__bottom{
        margin-top: 45px;
    }
}
@media (min-width: $btg-medium) {
    .page{
        &__title{
            font-size: 30px;
        }
        &__top{
            &-banner{
                background: #646362  url("/images/page-top.png") no-repeat center center;
                padding-bottom: 30px;
            }
        }
    }
}

@media (min-width: $IpadPro) { 
    .page{
        &__top{
            &-banner{
                margin-bottom: 25px;
                padding-bottom: 60px;
                background-size: cover;
                .page__title{
                    margin-top: 75px;
                }
            }
        }
        &__title{
            font-size: 38px;
            margin-bottom: 25px;
            span {
                position: relative;
                padding: 0  50px;
                &::after,
                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 35px;
                    height: 2px;
                    background-color: $brown2;
                }
                &::before{
                    left: 0;
                }
                &::after{
                    right: 0;
                }

            }
        }
        &__top{
            &--bg{
                .page__title{
                    margin-top: 80px;
                }
            }
            &--mb{
               margin-bottom: 30px;  
            }
        }
        
    }
    
}

@media (min-width: $btg-extraLarge) {
    .page{
        &__title{
             font-size: 44px;
        }
        &__bottom{
            margin-top: 65px;
        }
    }
}