@font-face {
    font-family: 'merriweatheritalic';
    src: url('/fonts/merrieweather/merriweather-italic-webfont.eot');
    src: url('/fonts/merrieweather/merriweather-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/merrieweather/merriweather-italic-webfont.woff2') format('woff2'),
         url('/fonts/merrieweather/merriweather-italic-webfont.woff') format('woff'),
         url('/fonts/merrieweather/merriweather-italic-webfont.ttf') format('truetype'),
         url('/fonts/merrieweather/merriweather-italic-webfont.svg#merriweatheritalic') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
