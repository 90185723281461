.breadcrumbs{
    margin-bottom: 40px;
}

.breadcrumbs__list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin:  0 -8px;
}

.breadcrumbs__item{
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 8px;
    // display: none;
    text-align: center;
    &:first-child,
    &:last-child{
        display: block;
    }

    &:not(:last-of-type) a{
        position: relative;
        &::before{
            content: " ";
            position: absolute;
            border: solid $text-color;
            border-width: 0px 1px 1px 0;
            display: block;
            padding: 2px;
            transform: translateY(-50%) rotate(-45deg);
            right: -8px;
            top: 50%;
        }
    }
    &:last-child a{
        // color: #cfcfcf;
        cursor: default;
    }
}

.breadcrumbs__link{
    color: $text-color;
    font-size: 12px;
    line-height: 14px;
    &:hover,
    &:focus{
        color: $text-color;
    }
    span{
        line-height: 20px;
    }
}

@media (min-width: $btg-medium){
    .breadcrumbs__link{
        span{
            line-height: 25px;
        }
    }
}

@media (max-width: $btg-large){
    // .breadcrumbs{
    //     margin-bottom: 30px;
    // }
}

@media (max-width: $btg-medium){

    // .breadcrumbs{
    //     margin-bottom: 35px;
    // }

    .breadcrumbs__item{
        &:not(:last-of-type) a{
        }         
    }
    // .breadcrumbs__item{
    //     display: none;
    // }

    // .breadcrumbs__item:nth-last-child(2),
    // .breadcrumbs__item:nth-last-child(3) {
    //     display: flex;
    // }
    // .breadcrumbs__item:nth-last-child(2){
    //     & > a::before{
    //         display: none;
    //     }
    // }
}
